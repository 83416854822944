<template>
    <div>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item label="发布日期">
                <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    align="right">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="发布国家">
                <el-select v-model="dataForm.cc" clearable placeholder="all">
                    <el-option v-for="item in countryCodeOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="用户语种">
                <el-select v-model="dataForm.lang" clearable placeholder="all">
                    <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布状态">
                <el-select v-model="dataForm.status" clearable placeholder="all">
                    <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="覆盖IP">
                <el-select v-model="dataForm.virIP" clearable placeholder="all" @change="selectIpChange">
                    <el-option v-for="item in virIPOpt" :key="item.ip" :label="item.ipName" :value="item.ip">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="覆盖角色">
                <el-select v-model="dataForm.virUser" clearable placeholder="--请选择--">
                    <el-option v-for="item in virUserOpt" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="getDataList()" type="primary">搜索</el-button>
                <el-button @click="refresh()">重置</el-button>
                <el-button @click="addOrUpdateHandle(0)" type="danger">新增</el-button>
                <!-- <el-button @click="exportList()"  style="background-color: #00BFBF; color: #fff;">导出</el-button> -->
            </el-form-item>
        </el-form>

        <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%;"
            :default-sort="{ prop: 'id', order: 'descending' }">

            <el-table-column prop="id" width="100" header-align="center" align="center" label="任务ID" sortable>
            </el-table-column>

            <el-table-column prop="sendCcList" header-align="center" align="center" label="发布国家">
                <template slot-scope="scope">
                    {{ splitStr(scope.row.sendCcList) }}
                </template>
            </el-table-column>
            <el-table-column prop="langList" header-align="center" align="center" label="用户语种">
                <template slot-scope="scope">
                    {{ splitStr(scope.row.langList) }}
                </template>
            </el-table-column>
            <el-table-column prop="sendTimeList" header-align="center" align="center" width="120" label="发送时间">
                <template slot-scope="scope">
                    {{ splitStr(scope.row.sendTimeList) }}
                </template>
            </el-table-column>

            <el-table-column prop="status" header-align="center" align="center" label="发布状态">
                <template slot-scope="scope">
                    <div>
                        <el-tag v-if="scope.row.status === 1" type="warning">待开始</el-tag>
                        <el-tag v-else-if="scope.row.status === 2" type="success">进行中</el-tag>
                        <el-tag v-else-if="scope.row.status === 0" type="danger">已终止</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="ipList" header-align="center" width="200px" align="center" label="覆盖IP">
                <template slot-scope="scope">
                    {{ getVirIpLabel(virIPOpt, scope.row.ipList, 'ip', 'ipName') }}
                </template>
            </el-table-column>
            <el-table-column prop="virUserList" header-align="center" align="center" label="覆盖角色" width="200px">
                <template slot-scope="scope">
                    {{ getVirUserLabel(scope.row.ipList, scope.row.virUserList) }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="isAllPerson" width="100px" header-align="center" align="center" label="是否人群包">
                <template slot-scope="scope">
                    <div>
                        <el-tag v-if="scope.row.status === 0" type="danger">否</el-tag>
                        <el-tag v-else-if="scope.row.status === 1" type="success">是</el-tag>
                    </div>
                </template>
            </el-table-column> -->

            <el-table-column prop="pushContentList" width="150px" header-align="center" align="center" label="push内容">
                <template slot-scope="scope">
                    {{ getContentList(scope.row.pushContentList) }}
                </template>

            </el-table-column>

            <!-- <el-table-column prop="createPerson" header-align="center" align="center" label="操作人">
            </el-table-column> -->

            <el-table-column prop="atime" width="180px" header-align="center" align="center" label="任务创建时间">
            </el-table-column>
            <el-table-column prop="opName" header-align="center" align="center" label="配置人">
            </el-table-column>
            <el-table-column prop="remark" width="200" header-align="center" align="center" label="备注">
                <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.remark ? '/' : scope.row.remark }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.status !== 0" type="text" style="color: rgba(0, 191, 191, 1)"
                        @click="handleStopPush(scope.row.id)">终止任务
                    </el-button>
                    <el-button type="text" @click="addOrUpdateHandle(scope.row)" style="color: rgba(236, 128, 141, 1)">查看详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>
<script>
import AddOrUpdate from "./push-add-or-update";
import { langOpt, pickerOptions } from "@/views/common/commonOpt";

const sourceDataForm = {
    id: '',
    lang: '',
    status: '',
    virIP: '',
    virUser: '',
    cc: '',
    startTime: '',
    endTime: '',
    createTimeArr: [],
}
export default {
    data() {
        return {
            dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            countryCodeOpt: [
                {
                    value: 'all',
                    label: 'ALL'
                }
            ],
            langOpt: langOpt,
            statusOpt: [
                {
                    value: 0,
                    label: '已终止'
                },
                {
                    value: 1,
                    label: '待进行'
                },
                {
                    value: 2,
                    label: '进行中'
                },
            ],
            virIPOpt: [],
            virUserOpt: [],
            virMapOpt: [],
            pickerOptions: pickerOptions,
            addOrUpdateVisible: true
        }
    },
    components: {
        AddOrUpdate
    },
    activated() {
        this.getDataList()
        this.getIpList()
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        refresh() {
            this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
            this.pageIndex = 1
            this.pageSize = 10
            this.getDataList()
        },
        getIpList() {
            this.$http({
                url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=0`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    if (data.result) {
                        this.virIPOpt = JSON.parse(JSON.stringify(data.result))
                        this.virMapOpt = JSON.parse(JSON.stringify(data.result))
                        this.virIPOpt.map(e => { delete e.virUserResDtoList }) //然后删除属性virUserResDtoList
                        this.virIPOpt = this.virIPOpt.filter(item => !["", null, undefined, NaN].includes(item.ip))
                    }
                } else {
                    this.$message.error(data.msg)
                }
            })
        },
        selectIpChange(val) {
            this.virUserOpt = []
            this.dataForm.virUser = ''
            if (val) {
                this.$http({
                    url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=${val}`),
                    method: 'post',
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        if (data.result) {
                            this.virUserOpt = data.result[0].virUserResDtoList
                            let map = new Map();
                            for (let item of this.virUserOpt) {
                                if (!map.has(item.id)) {
                                    map.set(item.id, item)
                                }
                            }
                            this.virUserOpt = [...map.values()]

                            this.virUserOpt = this.virUserOpt.filter(function (item, index) {
                                return item['id'] != 'all'
                            })
                            this.virUserOpt.push({
                                id: 'all',
                                name: 'ALL'
                            })
                        }
                    }
                })
            } else {
                this.dataForm.virUser = ''
            }

        },
        getVirIpLabel(list, ipstr, value, label) {
            let temp = []
            let ipList = ipstr.replace(/\[|]/g, '').replace(/["']/g, '').split(',')
            ipList.forEach(ip => {
                if (ip != '' && Array.isArray(list) && list.length != 0) {
                    let str = !list.find(item => item[value] == ip) ? ip : list.find(item => item[value] == ip)[label]
                    temp.push(str)
                } else {
                    temp.push(ip)
                }
            })
            return temp.join('\n\r').replace(/\[|]/g, '')
        },
        getVirUserLabel(ipStr, virUserStr) {
            let virUserOpt = []
            let virUser = []
            let ipList = ipStr.replace(/\[|]/g, '').replace(/["']/g, '').split(',')
            let virUserList = virUserStr.replace(/\[|]/g, '').replace(/["']/g, '').split(',')

            ipList.forEach(ip => {
                if (ip != '') {
                    virUserOpt = !this.virMapOpt.find(item => item['ip'] == ip) ? virUserOpt.concat([]) : virUserOpt.concat(this.virMapOpt.find(item => item['ip'] == ip).virUserResDtoList)
                }
            })

            virUserList.forEach(id => {
                if (id != '' && virUserOpt.length != 0) {
                    let str = !virUserOpt.find(item => item['id'] == id) ? id : virUserOpt.find(item => item['id'] == id)['name']
                    virUser.push(str)
                }
            })
            return virUser.join('\n\r').replace(/\[|]/g, '')

        },
        getContentList(contentStr) {
            let contentObj = eval("(" + contentStr + ")")
            let arLength = contentObj.ar ? contentObj.ar.length : 0
            let enLength = contentObj.en ? contentObj.en.length : 0
            if (arLength === 0) {
                return `en: ${enLength} 条`
            } else if (enLength === 0) {
                return `ar: ${arLength} 条`
            } else {
                return `ar: ${arLength} 条\n en: ${enLength} 条`
            }
        },
        splitStr(str) {
            let list = str.replace(/\[|]/g, '').split(',')
            return list.join('\n\r').replace(/["']/g, "")
        },

        // 获取数据列表
        getDataList() {
            this.dataListLoading = true
            // this.url = this.$http.adornUrl(
            //     `/sys/oss/upload?token=${this.$cookie.get('token')}`
            // )
            this.$http({
                url: this.$http.adornUrl('/admin/pushTaskNew/list'),
                method: 'post',
                data: this.$http.adornData({
                    ...this.dataForm,
                    startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
                    endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
                    page: this.pageIndex,
                    limit: this.pageSize,
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.result.data
                    this.totalPage = data.result.last_page
                } else {
                    this.$message.error(data.msg)
                }
                this.dataListLoading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        // 新增 / 修改
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id)
            })
        },
        handleStopPush(id) {
            this.$confirm('点击后任务下配置push将不会再被发送，请确认是否取消推送此任务', '提示', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                this.$http({
                    url: this.$http.adornUrl(`/admin/pushTaskNew/pause?taskId=${id}`),
                    method: 'post',
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        this.getDataList()
                        this.$message({
                            message: '终止任务成功',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.visible = false
                            }
                        })
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },
        // exportList() {
        //     const exportData = {
        //         ...this.dataForm,
        //         updateTimeStart: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        //         updateTimeEnd: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
        //     }
        //     const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
        //     window.open(
        //         this.$http.adornUrl('/admin/gift/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
        //         "_blank"
        //     );
        // }
    },
}
</script>
<style scoped lang="scss">
::v-deep(.el-table .cell) {
    white-space: pre-line;
}

.el-table {
    .el-button+.el-button {
        margin-left: 0;
    }
}
</style>

